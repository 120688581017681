<template>
  <div class="d-flex-column align-center main">
    <div class="main full-width input-underline">
      <el-card v-if="form || aqsForm">
        <div class="mw-400 main" :key="componentKey">
          <h3 class="mt-0">Additional Questions</h3>
          <el-form label-position="top" class="">
            <div v-if="form && nationalId === 32">
              <SuperInput
                id="schoolName"
                v-if="isMinorConsistent"
                :field="form.schoolName"
                v-model="form.schoolName.value"
                :errors.sync="form.schoolName.errors"
                :querySearch="getSchoolList"
              />
              <SuperInput
                id="schoolGrade"
                v-if="isMinorConsistent"
                :field="form.schoolGrade"
                v-model="form.schoolGrade.value"
                :errors.sync="form.schoolGrade.errors"
              />
              <!-- <SuperInput
                id="referee"
                v-if="current.type === 'referee'"
                :field="form.refereeType"
                v-model="form.refereeType.value"
              /> -->
              <SuperInput
                id="trainer"
                v-if="current.type === 'trainer'"
                :field="form.trainerType"
                v-model="form.trainerType.value"
                :errors.sync="form.trainerType.errors"
              />
              <SuperInput
                id="volunteer"
                v-if="current.type === 'volunteer'"
                :field="form.volunteerType"
                v-model="form.volunteerType.value"
                :errors.sync="form.volunteerType.errors"
              />
              <SuperInput
                id="coach"
                v-if="current.type === 'coach'"
                :field="form.coachType"
                v-model="form.coachType.value"
                :errors.sync="form.coachType.errors"
              />
              <SuperInput
                id="masters"
                v-if="current.type === 'player-masters'"
                :field="form.mastersTeam"
                v-model="form.mastersTeam.value"
                :errors.sync="form.mastersTeam.errors"
              />

              <SuperInput
                id="how"
                v-if="form.how"
                :field="form.how"
                v-model="form.how.value"
                :errors.sync="form.how.errors"
              />

              <SuperInput
                id="shirtsize"
                v-if="form.shirtSize"
                :field="form.shirtSize"
                v-model="form.shirtSize.value"
                :errors.sync="form.shirtSize.errors"
              />
            </div>
            <div v-if="form && nationalId === 31">
              <SuperInput
                id="interestedTfa"
                v-if="form.interestedIn"
                :field="form.interestedIn"
                v-model="form.interestedIn.value"
                :errors.sync="form.interestedIn.errors"
              />
              <SuperInput
                id="occupationTfa"
                v-if="form.occupation"
                :field="form.occupation"
                v-model="form.occupation.value"
                :errors.sync="form.occupation.errors"
              />
              <SuperInput
                id="schoolNameTfa"
                v-if="isMinorConsistent"
                :field="form.schoolName"
                v-model="form.schoolName.value"
                :errors.sync="form.schoolName.errors"
                :querySearch="getSchoolList"
              />
              <SuperInput
                id="schoolGradeTfa"
                v-if="isMinorConsistent"
                :field="form.schoolGrade"
                v-model="form.schoolGrade.value"
                :errors.sync="form.schoolGrade.errors"
              />
              <SuperInput
                id="schoolProgramTfa"
                v-if="isMinorConsistent"
                :field="form.schoolProgram"
                v-model="form.schoolProgram.value"
                :errors.sync="form.schoolProgram.errors"
              />
              <SuperInput
                id="volunteerTfa"
                v-if="current.type === 'volunteer'"
                :field="form.volunteerType"
                v-model="form.volunteerType.value"
                :errors.sync="form.volunteerType.errors"
              />
              <SuperInput
                id="onbehalfTfa"
                v-if="
                  (this.current.type === 'volunteer' || this.current.type === 'referee') &&
                  this.registerToAssoc
                "
                :field="form.onBehalfOf"
                v-model="form.onBehalfOf.value"
                :errors.sync="form.onBehalfOf.errors"
              />
              <SuperInput
                id="howTfa"
                v-if="form.how"
                :field="form.how"
                v-model="form.how.value"
                :errors.sync="form.how.errors"
              />
            </div>
            <el-row :gutter="60">
              <el-col
                :span="24"
                class="mt-1"
                v-for="(value, key) in aqsForm"
                :key="`aqs_${aqsForm[key]._id}`"
              >
                <SuperInput
                  :id="`aqs_${aqsForm[key]._id}`"
                  :field="aqsForm[key]"
                  v-model="aqsForm[key].value"
                  :errors.sync="aqsForm[key].errors"
                  :type="aqsForm[key].type"
                  :titleHtmlify="true"
                />
              </el-col>
            </el-row>

            <div
              class="mb-2 pt-1 f-14"
              v-if="!!this.allow18MRego && this.current.type === 'player' && this.isMinorConsistent"
            >
              <h3>18 Month Registration Policy</h3>
              <p class="f-14">
                To further enhance the Rugby League experience for participants born in the second
                half of the calendar year, the game is excited to be offering the 18-Month
                Registration Window.
              </p>
              <p class="f-14">
                The policy is designed to promote a more enjoyable environment for younger players
                who, for a number of reasons, may feel overly challenged in their calendar group by
                providing an opportunity for players who are born between July and December to
                register in the year level immediately below their calendar grouping.
              </p>
              <p class="f-14">
                If you feel you/your child will benefit by playing under this policy, then please
                apply below.
              </p>
              <p class="f-14">
                PLEASE NOTE: The policy is not offered for players meeting the demands of their
                calendar group regardless of their DOB.
              </p>
              <p class="f-14">
                <a
                  @click="form.applyFor18M.value = !form.applyFor18M.value"
                  class="information-link"
                >
                  Apply for 18 Month Provision
                </a>
              </p>
            </div>
            <div v-if="form && nationalId === 32">
              <SuperInput
                id="applicationReason"
                v-if="isMinorConsistent"
                :field="form.applicationReason"
                v-model="form.applicationReason.value"
                :errors.sync="form.applicationReason.errors"
              />
              <SuperInput
                id="height"
                v-if="isMinorConsistent"
                :field="form.height"
                v-model="form.height.value"
                :errors.sync="form.height.errors"
              />
              <SuperInput
                id="weight"
                v-if="isMinorConsistent"
                :field="form.weight"
                v-model="form.weight.value"
                :errors.sync="form.weight.errors"
              />
              <SuperInput
                id="applicationInformation"
                v-if="isMinorConsistent"
                :field="form.applicationInformation"
                v-model="form.applicationInformation.value"
                :errors.sync="form.applicationInformation.errors"
              />
            </div>
            <p v-if="form && nationalId === 31">
              <CheckBoxLine id="u18Tfa" v-if="isMinorConsistent" v-model="u18Acceptance" ref="u">
                <p class="f-14">
                  By ticking the box, I am indicating that I am the parent or guardian of the
                  applicant. I authorise and consent to the applicant participating in Touch
                  Football activities. In consideration of the applicant's membership with Touch
                  Football Australia and any other Touch Football organisation being accepted, I
                  expressly agree to be responsible for the applicant's behaviour and agree to
                  accept in my capacity as parent or guardian, the terms set out in this membership
                  declaration. In addition, I agree to be bound by and to comply with the Touch
                  Football Australia Constitution and any by-laws and policies made under it.
                </p>
              </CheckBoxLine>
            </p>
          </el-form>

          <!-- custom question -->
          <!-- <el-form label-position="top" class="">
            <el-row :gutter="60">
              <el-col :span="24" class="mt-1" v-for="(value, key) in aqsForm" :key="key">
                  <SuperInput
                    :field="aqsForm[key]"
                    v-model="aqsForm[key].value"
                    :errors.sync="aqsForm[key].errors"
                    :type="aqsForm[key].type"
                  />
              </el-col>
            </el-row>
          </el-form> -->
        </div>
      </el-card>
    </div>
    <div class="d-flex-column main mw-400">
      <div class="align-text-center">
        <el-button id="nextstep" type="primary" class="full-width" @click="next">Next</el-button>
      </div>
      <div class="align-text-center">
        <el-button class="full-width button-invisible" @click="back">Back</el-button>
      </div>
    </div>
    <ModalLayout
      v-if="showModal"
      @close="toggleModal"
      title="18 Month Registration Policy Information"
    >
      <div class="modal-content ml-1 mr-1">
        <p>
          To further enhance the Rugby League experience for some participants born in the second
          half of the calendar year, the game is excited to be offering the 18-Month Registration
          Window.
        </p>
        <p>
          The rationale behind the concept is to promote a more enjoyable environment for younger
          players who for a number of reasons may feel overly challenged in their calendar group and
          to minimise the impact of the Relative Age and Maturation Effects in Rugby League.
        </p>
        <p>
          By providing an opportunity for players who are born between July and December to register
          in the year level immediately below their calendar grouping, Rugby League is seeking to
          promote a players ongoing participation and personal development.
        </p>
        <p>
          The 18 Month Registration Policy is not available to girls turning 13 years of age in this
          calendar year to play in a 12 years mixed gender competition as per the NRL Mixed Gender
          Policy.
        </p>
        <p class="bold">
          Please note: This provision has not been introduced to allow for a player who is meeting
          the demands of their calendar year age group to utilise in order to dominate younger
          divisions. The purpose of the policy is to improve the Rugby League experience for players
          who are being adversely impacted by the Relative Age and Maturation Effects.
        </p>
        <p>
          If you believe this provision will enhance your child’s Rugby League experience, please
          answer YES below to access further information and application form or answer No to
          continue your registration. Once the application has been processed, your Club or Local
          League will notify you regarding the outcome of the application.
        </p>
        <p>
          Successful applicants of the 18-Month Registration provision will forfeit the following:
        </p>

        <ul>
          <li>Consideration for Junior Representative teams</li>
          <li>Eligibility for ‘portability’ clearance to transfer to another competition</li>
          <li>Eligibility in a Restricted Weight Division outside their calendar group</li>
          <li>
            The right to participate in their calendar group unless under special circumstances
            (e.g. a team would forfeit without their involvement)
          </li>
        </ul>
      </div>
    </ModalLayout>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import msg from "@/utils/constants/msg";
import { errorOrPass, flatten, makeForm, populate } from "@/utils/forms";
import { isMinorConsistent, calculateAge } from "@/utils/date/minor";
import SuperInput from "@/components/form/fields/SuperInput.vue";
import ModalLayout from "@/layouts/ModalLayout.vue";
import {
  schoolGrades,
  reasons18M,
  refereeTypes,
  sportsTrainerTypes,
  volunteerPrimaryRoles,
  volunteerPrimaryRolesTfa,
  coachingAgeGroups,
  mastersTeams,
  occupationOptionsTfa,
  interestedInOptionsTfa,
} from "@/utils/constants";
import { choiceStrategy, positiveIntStrategy } from "@/utils/validators/strategy";
import { endpointParams } from "@/utils/constants/api";
import CheckBoxLine from "@/components/form/fields/CheckBoxLine.vue";

export default {
  name: "PlayerAdditionalQuestions",
  components: { SuperInput, ModalLayout, CheckBoxLine },
  async mounted() {
    let form;
    let formData;
    if (this.nationalId === 32) {
      // NRL
      let settings = {};
      if (this.current && this.current.entity) settings = this.current.entity.settings;

      if (settings && settings.maxAge && settings.minAge && settings.duration && settings._id) {
        const { _id, duration, minAge, maxAge } = settings;
        const res = await this.$http.get(`/nrl/api/v1/portal/program-settings/${_id}`);

        let progSettings = { options: [] };
        if (res.data && res.data.data) progSettings = res.data.data;
        let progDetails = progSettings.options.find((o) => {
          return o.duration === duration && o.minAge <= minAge && maxAge <= o.maxAge;
        });
        this.hideShirtSize = progDetails && progDetails.hideShirtSize ? true : false;
      }

      if (this.isMinorConsistent) {
        formData = this.minorQuestions;

        // Additional Details Depending on Type (Minors)
        if (this.current.type === "coach") {
          formData.coachType = this.coachTypeQuestion;
        } else if (this.current.type === "referee") {
          formData.refereeType = this.refereeTypeQuestion;
        } else if (this.current.type === "trainer") {
          formData.trainerType = this.trainerTypeQuestion;
        } else if (this.current.type === "volunteer") {
          formData.volunteerType = this.volunteerTypeQuestion;
        } else if (this.current.type === "program") {
          formData.how = this.programHowQuestion;
          if (!this.hideShirtSize) formData.shirtSize = this.programShirtQuestion;
        }
        form = makeForm(formData);
      } else if (this.current.type === "referee") {
        form = makeForm({
          refereeType: this.refereeTypeQuestion,
        });
      } else if (this.current.type === "trainer") {
        form = makeForm({
          trainerType: this.trainerTypeQuestion,
        });
      } else if (this.current.type === "volunteer") {
        form = makeForm({
          volunteerType: this.volunteerTypeQuestion,
        });
      } else if (this.current.type === "coach") {
        form = makeForm({
          coachType: this.coachTypeQuestion,
        });
      } else if (this.current.type === "program") {
        const obj = {
          how: this.programHowQuestion,
        };
        if (!this.hideShirtSize) obj.shirtSize = this.programShirtQuestion;
        form = makeForm(obj);
      } else if (this.current.type === "player-masters") {
        form = makeForm({
          mastersTeam: {
            label: "Masters Team",
            component: "TypedSelect",
            options: mastersTeams.map((x) => ({ label: x, value: x })),
            validators: [choiceStrategy(mastersTeams.map((x) => ({ label: x, value: x })))],
          },
        });
      }
    } else if (this.nationalId === 31) {
      // TFA
      if (this.isMinorConsistent) {
        formData = this.minorQuestionsTFA;

        // Additional Details Depending on other factors (Minors)
        if (calculateAge(this.current.profile.participantForm.dob) >= 14) {
          formData.occupation = this.occupationQuestionTfa;
        }
        if (this.current.type === "volunteer") {
          formData.volunteerType = this.volunteerTypeQuestionTfa;
        } else if (this.current.type === "program") {
          formData.how = this.programHowQuestion;
        }
      } else {
        formData = {
          interestedIn: this.interestedInQuestionTfa,
          occupation: this.occupationQuestionTfa,
        };

        // Additional Details Depending on other factors (Not Minors)
        if (this.current.type === "volunteer") {
          formData.volunteerType = this.volunteerTypeQuestionTfa;
        }
        if (this.current.type === "program") {
          formData.how = this.programHowQuestion;
        }
        if (
          (this.current.type === "volunteer" || this.current.type === "referee") &&
          this.registerToAssoc
        ) {
          formData.onBehalfOf = this.onBehalfOfQuestionTfa;
        }
      }
      form = makeForm(formData);
    }

    if (form) {
      form = populate(form, {
        height: _.get(this.current.profile, "participantForm.height", 0),
        weight: _.get(this.current.profile, "participantForm.weight", 0),
        ...this.current.additionalDetails,
      });
      this.form = form;
    }
    this.componentKey += 1;

    // custom additional questions reform
    const {
      priceItem: { additionalQs },
    } = this.current;

    _.forEach(additionalQs, ({ type, _id, fieldName, question, required, meta: { options } }) => {
      this.$set(this.aqsForm, _.camelCase(fieldName) + "_" + _.camelCase(_id), {
        defaultType: type,
        type: type === 3 ? "number" : "text",
        // eslint-disable-next-line no-nested-ternary
        component: [1, 3].includes(type) ? "PlainInput" : type === 2 ? "SelectSP" : "CheckboxSP",
        options,
        label: question,
        required,
        displayed: () => true,
        value: [1, 2, 3].includes(type) ? "" : [],
        errors: [],
        _id: _.camelCase(fieldName) + "_" + _.camelCase(_id),
      });
    });
    this.aqsForm = makeForm(this.aqsForm);
  },
  data() {
    return {
      componentKey: 0,
      componentKey1: 10,
      aqsForm: {},
      form: undefined,
      showModal: false,
      u18Acceptance: false,
      hideShirtSize: undefined,
      minorQuestions: {
        schoolName: {
          label: "School Name/Suburb",
          component: "TypedSelect",
          validators: [choiceStrategy([{ value: "Not enrolled in a school" }, { value: "Other" }])],
          options: [{ value: "Not enrolled in a school" }, { value: "Other" }],
          displayed: () => this.isMinorConsistent,
        },
        schoolGrade: {
          label: "School Grade",
          component: "SelectSP",
          options: schoolGrades.concat([{ label: "N/A", value: "N/A" }]),
          displayed: () =>
            this.isMinorConsistent && this.form.schoolName.value !== "Not enrolled in a school",
        },
        applyFor18M: {
          label: "Apply for 18 Month Provision",
          options: [
            {
              value: true,
              label: "Yes",
            },
            {
              value: false,
              label: "No",
            },
          ],
          component: "SelectSP",
          required: true,
          displayed: () => false,
        },
        applicationReason: {
          label: "18 Month Application Reason",
          component: "SelectSP",
          options: reasons18M,
          displayed: () => !!this.form.applyFor18M.value && this.isMinorConsistent,
        },
        height: {
          label: "Height (cm)",
          validators: [positiveIntStrategy],
          displayed: () => !!this.form.applyFor18M.value && this.isMinorConsistent,
        },
        weight: {
          label: "Weight (kg)",
          validators: [positiveIntStrategy],
          displayed: () => !!this.form.applyFor18M.value && this.isMinorConsistent,
        },
        applicationInformation: {
          label: "Please provide additional information",
          requried: false,
          displayed: () =>
            !!this.form.applyFor18M.value &&
            this.isMinorConsistent &&
            this.form.applicationReason.value === "Other",
        },
      },
      coachTypeQuestion: {
        label: "Coaching Age Group",
        component: "SelectSP",
        options: coachingAgeGroups,
      },
      refereeTypeQuestion: {
        label: "Referee Type",
        component: "SelectSP",
        options: [],
      },
      trainerTypeQuestion: {
        label: "Sports Trainer Type",
        component: "SelectSP",
        options: sportsTrainerTypes,
      },
      volunteerTypeQuestion: {
        label: "Volunteer Primary Role",
        component: "SelectSP",
        options: volunteerPrimaryRoles,
      },
      volunteerTypeQuestionTfa: {
        label: "Volunteer Primary Role",
        component: "SelectSP",
        options: volunteerPrimaryRolesTfa,
      },
      programHowQuestion: {
        label: "How did you find out about this program?",
        component: "SelectSP",
        options: [
          "PlayNRL Facebook page",
          "Other Social Media Sites",
          "School",
          "Information Flyer",
          "Your Junior Rugby League Club",
          "Word of Mouth",
          "Google Search",
          "Other",
        ].map((x) => ({ label: x, value: x })),
        displayed: () => this.current.type === "program",
      },
      programShirtQuestion: {
        label: "Please provide a shirt size",
        requried: true,
        component: "SelectSP",
        options: ["6", "8", "10", "12", "14", "16", "18", "20"].map((x) => ({
          label: x,
          value: x,
        })),
        displayed: () => this.current.type === "program",
      },
      minorQuestionsTFA: {
        occupation: {
          label: "Occupation",
          component: "SelectSP",
          options: occupationOptionsTfa,
          displayed: () => calculateAge(this.current.profile.participantForm.dob) >= 14,
        },
        interestedIn: {
          label: "How did you become interested in Touch Football",
          component: "SelectSP",
          options: interestedInOptionsTfa,
        },
        schoolName: {
          label: "School Name/Suburb",
          component: "TypedSelect",
          validators: [choiceStrategy([{ value: "Not enrolled in a school" }, { value: "Other" }])],
          options: [{ value: "Not enrolled in a school" }, { value: "Other" }],
          displayed: () => this.isMinorConsistent,
        },
        schoolGrade: {
          label: "School Grade",
          component: "SelectSP",
          options: schoolGrades.concat([{ label: "N/A", value: "N/A" }]),
          displayed: () =>
            this.isMinorConsistent && this.form.schoolName.value !== "Not enrolled in a school",
        },
        schoolProgram: {
          label: "Have you participated in a school program?",
          component: "SelectSP",
          options: [
            {
              value: true,
              label: "Yes",
            },
            {
              value: false,
              label: "No",
            },
          ],
          displayed: () => this.isMinorConsistent,
        },
        onBehalfOf: {
          label: "Are you registering on behalf of a team or club?",
          displayed: () =>
            (this.current.type === "volunteer" || this.current.type === "referee") &&
            this.registerToAssoc,
        },
      },
      occupationQuestionTfa: {
        label: "Occupation",
        component: "SelectSP",
        options: occupationOptionsTfa,
        displayed: () => calculateAge(this.current.profile.participantForm.dob) >= 14,
      },
      interestedInQuestionTfa: {
        label: "How did you become interested in Touch Football",
        component: "SelectSP",
        options: interestedInOptionsTfa,
      },
      onBehalfOfQuestionTfa: {
        label: "Are you registering on behalf of a team or club?",
      },
      aQsForm: {},
    };
  },
  computed: {
    ...mapGetters("registration", ["current"]),
    isMinorConsistent() {
      return isMinorConsistent(this.current.profile.participantForm.dob);
    },
    allow18MRego() {
      if (
        this.current.entity.orgtree &&
        this.current.entity.orgtree.association &&
        this.current.entity.orgtree.association["18MRegistration"]
      ) {
        const birthMonth = parseInt(this.current.profile.participantForm.dob.slice(5, 7));
        if (birthMonth >= 7 && birthMonth <= 12) {
          return this.current.entity.orgtree.association["18MRegistration"];
        }
        return false;
      }
      return false;
    },
    nationalId() {
      return this.current.entity.orgtree.national ? this.current.entity.orgtree.national._id : 32;
    },
    registerToAssoc() {
      return this.current.entity ? this.current.entity.entityType === "association" : false;
    },
  },
  methods: {
    ...mapActions("views", ["triggerEvent"]),
    ...mapActions("registration", ["updateCurrent"]),
    back() {
      this.$router.go(-1);
    },
    next() {
      this.triggerEvent();
      let ignoredFields = [];
      if (
        this.isMinorConsistent &&
        this.form &&
        this.form.applyFor18M &&
        this.form.applyFor18M.value === false
      ) {
        ignoredFields = ["applicationReason", "applicationInformation", "height", "weight"];
      }
      ignoredFields.push("refereeType");
      // Validate standard fields
      if (this.form && !errorOrPass(this.form, ignoredFields, this)) return;

      // validate additional questions form
      if (this.aqsForm && !errorOrPass(this.aqsForm, [], this)) return;

      // Validate u18Acceptance if required
      if (this.nationalId === 31 && this.isMinorConsistent && this.u18Acceptance === false) {
        window.scrollTo(0, 0);
        this.$store.commit("views/PUSH_NOTIFICATION", {
          msg: "You must accept the Under 18 Terms and Conditions",
          type: "warning",
        });
        return;
      }

      const flatForm = this.form && flatten(this.form);

      // values of additional questions
      const answers = this.aqsForm && flatten(this.aqsForm);

      if (
        this.form &&
        this.isMinorConsistent &&
        this.form.applyFor18M &&
        this.form.applyFor18M.value === true
      ) {
        // Push additional forms fields to store against the member profile === height, weight
        // TODO: add default dispensation data into member data
        this.$store.commit("root/LOADING", true);
        this.$http
          .put(endpointParams.profile.updateMinor(this.current.id), {
            height: parseInt(flatForm.height),
            weight: parseInt(flatForm.weight),
          })
          .then((response) => {
            this.$store.commit("root/LOADING", false);
            if (response.data && response.data.data === this.current.id) {
              // Push to the saleable items screen on success
              // eslint-disable-next-line max-len
              const additionalDetails = Object.assign(
                {},
                { answers },
                flatForm,
                this.current.additionalDetails,
                { applyFor18M: this.form.applyFor18M.value }
              );
              this.updateCurrent({ additionalDetails });
              this.$router.push({ name: "chooseaddon" });
            } else {
              window.scrollTo(0, 0);
              this.$store.commit("views/PUSH_NOTIFICATION", {
                msg: msg.error.apiError,
                type: "warning",
              });
            }
          })
          .catch((error) => {
            this.$store.commit("root/LOADING", false);
            window.scrollTo(0, 0);
            this.$store.commit("views/PUSH_NOTIFICATION", {
              msg:
                error.response && error.response.data && error.response.data.message
                  ? error.response.data.message
                  : msg.error.apiError,
              type: "warning",
            });
          });
      } else {
        // Update any additional form fields
        const additionalDetails = Object.assign(
          {},
          { answers },
          flatForm,
          this.current.additionalDetails
        );
        if (this.nationalId === 31 && this.isMinorConsistent)
          additionalDetails.u18Acceptance = this.u18Acceptance;
        this.updateCurrent({ additionalDetails });
        this.$router.push({ name: "chooseaddon" });

        // Push to verification or saleable items
        // if (this.current.verification.pending && this.nationalId === 32) {
        //   this.$router.push({ name: 'verificationupload' });
        // } else {
        //   this.$router.push({ name: 'chooseaddon' });
        // }
      }
    },
    getSchoolList(queryString, cb) {
      this.$store.commit("root/LOADING", true);
      this.$http
        .post("/nrl/api/v1/portal/registration/schools/finder", { name: queryString })
        .then((response) => {
          if (response && response.data && response.data.data) {
            const schoolsData = response.data.data.map((x) => ({
              value: `${x.name}, ${x.address.suburb}`,
            }));
            const schools = [{ value: "Not enrolled in a school" }].concat(schoolsData, [
              { value: "Other" },
            ]);
            cb(schools);
            this.form.schoolName.validators = [choiceStrategy(schools)];
          } else {
            window.scrollTo(0, 0);
            this.$store.commit("views/PUSH_NOTIFICATION", {
              msg: msg.error.apiError,
              type: "warning",
            });
          }
        })
        .catch(() => {
          window.scrollTo(0, 0);
          this.$store.commit("views/PUSH_NOTIFICATION", {
            msg: msg.error.apiError,
            type: "warning",
          });
        });
      this.$store.commit("root/LOADING", false);
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 0 !important;
}

::v-deep .el-input {
  .el-input__suffix {
    z-index: 1;
  }
}

::v-deep .form-control label {
  bottom: 12px;
}

.information-link {
  font-size: 0.875rem;
  cursor: pointer;
  text-decoration: underline;
}

.modal-content {
  margin-bottom: 4rem;
  @media (min-width: $sm) {
    margin-bottom: 0;
  }
  .bold {
    font-family: $fontBold;
  }
}

.f-14 {
  font-size: 0.875rem;
}
</style>
